import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import userLocation from "../../img/user_location_icon.svg";

const SPEED_CONFIG = {
  slow: {
    durationPerKm: 8000,
    lookAhead: 10,
    samplingDensity: 0.005,
    maxDegreesPerFrame: 0.15,
    minDegreesPerFrame: 0.05
  },
  medium: {
    durationPerKm: 4000,
    lookAhead: 8,
    samplingDensity: 0.01,
    maxDegreesPerFrame: 0.25,
    minDegreesPerFrame: 0.10
  },
  fast: {
    durationPerKm: 1500,
    lookAhead: 5,
    samplingDensity: 0.02,
    maxDegreesPerFrame: 0.35,
    minDegreesPerFrame: 0.2
  },
  ultrafast: {
    durationPerKm: 800,
    lookAhead: 5,
    samplingDensity: 0.03,
    maxDegreesPerFrame: 0.5,
    minDegreesPerFrame: 0.25
  },
  hyperspeed: {
    durationPerKm: 400,
    lookAhead: 10,
    samplingDensity: 0.04,
    maxDegreesPerFrame: 0.8,
    minDegreesPerFrame: 0.3
  }
};

const DEFAULT_CAMERA_OPTIONS = {
  useElevationAwarePitch: true,
  basePitch: 65,
  minPitch: 45,
  maxPitch: 80,
  pitchDamping: 0.1,
  gradientThreshold: 5, // gradient % threshold to adjust pitch
  pitchPerGradientPercent: 1.5,
  elevationLookAheadMultiplier: 1.5,
  downhillPitchMultiplier: 1.8,
  smoothingFactor: 0.85,
  minDegreesPerFrame: 0.1,
  maxDegreesPerFrame: 0.5
};

// Function to check bearing consistency and compute average if consistent
function getConsistentAverageBearing(bearings) {
  if (bearings.length === 0) return null;
  const radians = bearings.map(b => (b * Math.PI) / 180);
  let sumCos = 0;
  let sumSin = 0;
  for (const r of radians) {
    sumCos += Math.cos(r);
    sumSin += Math.sin(r);
  }
  const C = sumCos / bearings.length;
  const S = sumSin / bearings.length;
  const R = Math.sqrt(C * C + S * S);
  // R > 0.985 corresponds to a small angular spread (~10 degrees standard deviation)
  if (R > 0.985) {
    const avgRad = Math.atan2(sumSin, sumCos);
    let avgDeg = (avgRad * 180 / Math.PI + 360) % 360;
    return avgDeg;
  }
  return null;
}

/**
 * Smooth, continuous trail preview (with optional distance/elevation callback).
 * Returns an animation controller with methods: pause(), resume(), stop(), fastForward(), rewind(), isPaused()
 */
export function previewTrail(
  map,
  trailCoords,
  mode = "medium",
  markerOptions = {},
  progressCallback,
  cameraOptions = {}
) {
  // Merge camera options with defaults
  const camera = { ...DEFAULT_CAMERA_OPTIONS, ...cameraOptions };
  const config = SPEED_CONFIG[mode] || SPEED_CONFIG.medium;

  // Ensure each coordinate has elevation (defaulting to 0)
  const coords3d = trailCoords.map(coord =>
    coord.length > 2 ? [coord[0], coord[1], coord[2]] : [coord[0], coord[1], 0]
  );

  // Show a "processing..." overlay
  const processingEl = document.createElement("div");
  processingEl.style = `
    position:absolute;
    top:10px;
    left:10px;
    background:rgba(0,0,0,0.7);
    color:white;
    padding:5px 10px;
    border-radius:4px;
    z-index:999;
  `;
  processingEl.textContent = "Processing trail data...";
  map.getContainer().appendChild(processingEl);

  // Precompute cumulative distances (in km) along the trail for elevation interpolation
  const { cumulativeDistKm } = computeCumulativeDistances(coords3d);

  // Build a 2D line for turf operations
  const coords2d = coords3d.map(c => [c[0], c[1]]);
  const fullLine = turf.lineString(coords2d);

  // Optionally simplify the line if there are many points
  const simplifiedLine = coords2d.length > 1000
    ? turf.simplify(fullLine, { tolerance: 0.0001, highQuality: true })
    : fullLine;

  // Compute the total distance (in km)
  const totalDistKm = turf.length(simplifiedLine, { units: "kilometers" });

  // Sample points at regular intervals along the trail
  const samplingInterval = config.samplingDensity;
  const sampledPoints = sampleTrailLineImproved(simplifiedLine, totalDistKm, samplingInterval);

  // For each sampled point, interpolate elevation using the cumulative distances
  sampledPoints.forEach(sp => {
    sp.elevation = getElevationAtDistanceKm(sp.distance, coords3d, cumulativeDistKm);
  });

  // Compute bearings and (if enabled) elevation-aware pitch for each sample
  const elevationLookAhead = config.lookAhead * camera.elevationLookAheadMultiplier / 1000;
  for (let i = 0; i < sampledPoints.length; i++) {
    sampledPoints[i].bearing = computeWeightedBearing(simplifiedLine, sampledPoints[i].distance, config.lookAhead);
    sampledPoints[i].pitch = camera.useElevationAwarePitch
      ? computePitchForElevation(coords3d, cumulativeDistKm, sampledPoints[i].distance, elevationLookAhead, camera)
      : camera.basePitch;
  }

  // Compute target bearings requiring at least 5 consistent coordinates
  const targetBearings = [];
  for (let i = 0; i < sampledPoints.length; i++) {
    const windowSize = 20;
    const end = Math.min(i + windowSize, sampledPoints.length);
    const bearingsInWindow = sampledPoints.slice(i, end).map(p => p.bearing);
    const avgBearing = getConsistentAverageBearing(bearingsInWindow);
    if (avgBearing !== null) {
      targetBearings[i] = avgBearing;
    } else {
      targetBearings[i] = i > 0 ? targetBearings[i - 1] : sampledPoints[0].bearing;
    }
    sampledPoints[i].targetBearing = targetBearings[i];
  }

  // Create/update the progress line layer
  if (!map.getSource("progressTrail")) {
    map.addSource("progressTrail", {
      type: "geojson",
      data: turf.lineString([[0, 0], [0, 0]])
    });
    map.addLayer({
      id: "progressTrail",
      type: "line",
      source: "progressTrail",
      layout: { "line-join": "round", "line-cap": "round" },
      paint: { "line-color": "#2cc2e3", "line-width": 5 }
    });
  }

  // Create the animated marker
  const markerElement = document.createElement("div");
  markerElement.className = "custom-marker";
  markerElement.style.backgroundImage = `url(${userLocation})`;
  markerElement.style.width = markerOptions.width || "30px";
  markerElement.style.height = markerOptions.height || "30px";
  markerElement.style.backgroundSize = "contain";
  markerElement.style.backgroundRepeat = "no-repeat";
  markerElement.style.backgroundPosition = "center";
  const marker = new mapboxgl.Marker({
    element: markerElement,
    rotationAlignment: markerOptions.rotationAlignment || "map",
    pitchAlignment: markerOptions.pitchAlignment || "auto",
    offset: markerOptions.offset || [0, 0]
  })
    .setLngLat(sampledPoints[0].position)
    .addTo(map);

  // Remove processing overlay and jump camera to the starting position
  processingEl.remove();
  map.jumpTo({
    center: sampledPoints[0].position,
    zoom: 14,
    pitch: camera.useElevationAwarePitch ? sampledPoints[0].pitch : camera.basePitch,
    bearing: sampledPoints[0].targetBearing // Use targetBearing
  });

  // Total duration based on trail distance and speed configuration
  const totalDuration = totalDistKm * config.durationPerKm;

  return animateFullTrailImproved({
    map,
    marker,
    sampledPoints,
    simplifiedLine,
    totalDistKm,
    duration: totalDuration,
    maxDegreesPerFrame: config.maxDegreesPerFrame,
    minDegreesPerFrame: config.minDegreesPerFrame,
    progressCallback,
    cameraOptions: camera
  });
}

// ---------------------- Internal Utility Functions ---------------------------

function computePitchForElevation(coords3d, cumulativeDistKm, currentDistKm, lookAheadKm, cameraOptions) {
  const totalDistKm = cumulativeDistKm[cumulativeDistKm.length - 1];
  const currentElev = getElevationAtDistanceKm(currentDistKm, coords3d, cumulativeDistKm);
  const aheadDist = Math.min(currentDistKm + lookAheadKm, totalDistKm);
  const aheadElev = getElevationAtDistanceKm(aheadDist, coords3d, cumulativeDistKm);
  const horizDist = aheadDist - currentDistKm;
  if (horizDist < 0.001) return cameraOptions.basePitch;
  const gradientPercent = ((aheadElev - currentElev) / (horizDist * 1000)) * 100;
  let pitch = cameraOptions.basePitch;
  if (Math.abs(gradientPercent) > cameraOptions.gradientThreshold) {
    if (gradientPercent > 0) {
      pitch = cameraOptions.basePitch - (gradientPercent - cameraOptions.gradientThreshold) * cameraOptions.pitchPerGradientPercent;
    } else {
      pitch = cameraOptions.basePitch - (Math.abs(gradientPercent) - cameraOptions.gradientThreshold) * cameraOptions.pitchPerGradientPercent * (cameraOptions.downhillPitchMultiplier || 1.0);
    }
  }
  return Math.max(cameraOptions.minPitch, Math.min(cameraOptions.maxPitch, pitch));
}

function computeCumulativeDistances(coords3d) {
  let total2dDistanceKm = 0;
  const cumulativeDistKm = [0];
  for (let i = 1; i < coords3d.length; i++) {
    const p1 = coords3d[i - 1],
      p2 = coords3d[i];
    const segDistKm = turf.distance(turf.point([p1[0], p1[1]]), turf.point([p2[0], p2[1]]), { units: "kilometers" });
    total2dDistanceKm += segDistKm;
    cumulativeDistKm.push(total2dDistanceKm);
  }
  return { cumulativeDistKm, total2dDistanceKm };
}

function getElevationAtDistanceKm(distKm, coords3d, cumulativeDistKm) {
  if (distKm >= cumulativeDistKm[cumulativeDistKm.length - 1])
    return coords3d[coords3d.length - 1][2];
  for (let i = 1; i < cumulativeDistKm.length; i++) {
    if (cumulativeDistKm[i] >= distKm) {
      const fraction = (distKm - cumulativeDistKm[i - 1]) / (cumulativeDistKm[i] - cumulativeDistKm[i - 1]);
      return coords3d[i - 1][2] + (coords3d[i][2] - coords3d[i - 1][2]) * fraction;
    }
  }
  return coords3d[coords3d.length - 1][2];
}

function sampleTrailLineImproved(line2D, totalDistKm, samplingInterval) {
  const sampledPoints = [];
  const seenPositions = new Set();
  const numSamples = Math.ceil(totalDistKm / samplingInterval) + 1;
  for (let i = 0; i <= numSamples; i++) {
    const dist = Math.min(i * samplingInterval, totalDistKm);
    const pt = turf.along(line2D, dist, { units: "kilometers" });
    const posKey = JSON.stringify(pt.geometry.coordinates.map(c => Math.round(c * 100000) / 100000));
    if (!seenPositions.has(posKey)) {
      seenPositions.add(posKey);
      sampledPoints.push({ position: pt.geometry.coordinates, distance: dist });
    }
  }
  // Ensure first and last points are included
  const startPt = turf.along(line2D, 0, { units: "kilometers" });
  const endPt = turf.along(line2D, totalDistKm, { units: "kilometers" });
  sampledPoints[0] = { position: startPt.geometry.coordinates, distance: 0 };
  sampledPoints[sampledPoints.length - 1] = { position: endPt.geometry.coordinates, distance: totalDistKm };
  return sampledPoints;
}

function computeWeightedBearing(line2D, distanceKm, lookAheadMeters) {
  const lookAheadKm = lookAheadMeters / 1000;
  const totalDistKm = turf.length(line2D, { units: "kilometers" });
  const bearings = [], weights = [];
  for (let ahead = 1; ahead <= 5; ahead++) {
    const segDist = Math.min(distanceKm + (lookAheadKm * ahead) / 3, totalDistKm);
    if (segDist <= distanceKm) continue;
    const startPt = turf.along(line2D, distanceKm, { units: "kilometers" });
    const endPt = turf.along(line2D, segDist, { units: "kilometers" });
    bearings.push(turf.bearing(startPt, endPt));
    weights.push(1 / ahead);
  }
  return averageBearing(bearings, weights);
}

function averageBearing(bearings, weights) {
  let x = 0, y = 0;
  for (let i = 0; i < bearings.length; i++) {
    const rad = (bearings[i] * Math.PI) / 180;
    x += Math.sin(rad) * weights[i];
    y += Math.cos(rad) * weights[i];
  }
  const avgRad = Math.atan2(x, y);
  let deg = (avgRad * 180) / Math.PI;
  return (deg + 360) % 360;
}

// -------------------- Animation Function --------------------

function animateFullTrailImproved(opts) {
  const {
    map,
    marker,
    sampledPoints,
    simplifiedLine,
    totalDistKm,
    duration,
    maxDegreesPerFrame,
    minDegreesPerFrame,
    progressCallback,
    cameraOptions
  } = opts;

  let lastFrameTime = performance.now();
  let currentBearing = sampledPoints[0].targetBearing; // Initialize with targetBearing
  let currentPitch = cameraOptions.useElevationAwarePitch ? sampledPoints[0].pitch : cameraOptions.basePitch;
  let requestId = null;
  let currentT = 0; // normalized progress (0 to 1)
  let paused = false;
  let finished = false;
  let resolveFunc;
  const promise = new Promise(resolve => { resolveFunc = resolve; });

  // Update the UI based on currentT
  function updateUI() {
    const currentDistance = totalDistKm * currentT;
    const { before, after } = findSurroundingPoints(currentDistance);
    const factor = (after.distance !== before.distance)
      ? (currentDistance - before.distance) / (after.distance - before.distance)
      : 0;
    const currentPos = interpolatePoints(before.position, after.position, factor);
    const currentElev = interpolateValue(before.elevation, after.elevation, factor);
    // Use targetBearing instead of bearing
    const targetBearing = interpolateAngle(before.targetBearing, after.targetBearing, factor);
    currentBearing = smoothBearingImproved(currentBearing, targetBearing, 16.67, minDegreesPerFrame, maxDegreesPerFrame);
    if (cameraOptions.useElevationAwarePitch) {
      const targetPitch = interpolateValue(before.pitch, after.pitch, factor);
      currentPitch = smoothPitchChange(currentPitch, targetPitch, 16.67, cameraOptions.minDegreesPerFrame, cameraOptions.maxDegreesPerFrame, cameraOptions.smoothingFactor);
    } else {
      currentPitch = cameraOptions.basePitch;
    }
    marker.setLngLat(currentPos);
    map.jumpTo({
      center: currentPos,
      bearing: currentBearing,
      pitch: currentPitch,
      zoom: 14
    });
    const progressCoords = getCoordsUpToDistance(currentDistance);
    map.getSource("progressTrail").setData(turf.lineString(progressCoords));
    if (progressCallback) {
      progressCallback(currentDistance, currentElev, cameraOptions.useElevationAwarePitch ? currentPitch : undefined);
    }
  }

  function frame() {
    if (paused) { requestId = null; return; }
    const now = performance.now();
    const deltaT = (now - lastFrameTime) / duration;
    lastFrameTime = now;
    currentT = Math.min(currentT + deltaT, 1);
    updateUI();
    if (currentT < 1) {
      requestId = requestAnimationFrame(frame);
    } else {
      finished = true;
      resolveFunc();
    }
  }

  function findSurroundingPoints(distanceKm) {
    let beforeIndex = 0;
    let low = 0, high = sampledPoints.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (sampledPoints[mid].distance < distanceKm) {
        beforeIndex = mid;
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    const afterIndex = Math.min(beforeIndex + 1, sampledPoints.length - 1);
    return { before: sampledPoints[beforeIndex], after: sampledPoints[afterIndex] };
  }

  function interpolatePoints(p1, p2, factor) {
    return [p1[0] + (p2[0] - p1[0]) * factor, p1[1] + (p2[1] - p1[1]) * factor];
  }

  function interpolateAngle(a1, a2, factor) {
    let diff = ((a2 - a1 + 180) % 360) - 180;
    if (diff < -180) diff += 360;
    return a1 + diff * factor;
  }

  function interpolateValue(v1, v2, factor) {
    return v1 + (v2 - v1) * factor;
  }

  function getCoordsUpToDistance(distance) {
    const coords = simplifiedLine.geometry.coordinates;
    const result = [coords[0]];
    let cumulative = 0;
    for (let i = 1; i < coords.length; i++) {
      const segDist = turf.distance(turf.point(coords[i - 1]), turf.point(coords[i]), { units: "kilometers" });
      if (cumulative + segDist <= distance) {
        result.push(coords[i]);
        cumulative += segDist;
      } else {
        const fraction = (distance - cumulative) / segDist;
        result.push([
          coords[i - 1][0] + (coords[i][0] - coords[i - 1][0]) * fraction,
          coords[i - 1][1] + (coords[i][1] - coords[i - 1][1]) * fraction
        ]);
        break;
      }
    }
    if (result.length < 2) result.push([...result[0]]);
    return result;
  }

  function smoothBearingImproved(current, target, deltaTime, minDeg, maxDeg) {
    const normCurrent = (current + 360) % 360;
    const normTarget = (target + 360) % 360;
    const cwDiff = (normTarget - normCurrent + 360) % 360;
    const ccwDiff = (normCurrent - normTarget + 360) % 360;
    const direction = cwDiff <= ccwDiff ? 1 : -1;
    const diffSize = Math.min(cwDiff, ccwDiff);
    const scale = Math.min(1, diffSize / 90);
    const baseDeg = minDeg + (maxDeg - minDeg) * scale;
    const degPerFrame = baseDeg * (deltaTime / 16.67);
    let newBearing;
    if (direction === 1) {
      newBearing = (cwDiff < degPerFrame) ? normTarget : (normCurrent + degPerFrame) % 360;
    } else {
      newBearing = (ccwDiff < degPerFrame) ? normTarget : (normCurrent - degPerFrame + 360) % 360;
    }
    return ((newBearing + 180) % 360) - 180;
  }

  function smoothPitchChange(current, target, deltaTime, minDeg, maxDeg, smoothing = 0.85) {
    const diff = target - current;
    const absDiff = Math.abs(diff);
    if (absDiff < 0.01) return target;
    const scale = Math.min(1, absDiff / 20);
    const baseDeg = minDeg + (maxDeg - minDeg) * scale;
    const maxChange = baseDeg * (deltaTime / 16.67);
    const smoothed = diff > 0 ? Math.min(absDiff, maxChange) : -Math.min(absDiff, maxChange);
    return Math.abs(smoothed) < absDiff ? current + smoothed * (1 - smoothing) : target;
  }

  // Start the animation loop
  requestId = requestAnimationFrame(frame);

  return {
    pause() {
      paused = true;
      if (requestId) { cancelAnimationFrame(requestId); requestId = null; }
    },
    resume() {
      if (finished || !paused) return;
      paused = false;
      lastFrameTime = performance.now();
      if (!requestId) requestId = requestAnimationFrame(frame);
    },
    stop() {
      if (finished) return;
      finished = true;
      paused = true;
      if (requestId) { cancelAnimationFrame(requestId); requestId = null; }
      resolveFunc();
    },
    isPaused() {
      return paused;
    },
    fastForward() {
      currentT = Math.min(currentT + (1 / totalDistKm), 1);
      updateUI();
    },
    rewind() {
      currentT = Math.max(currentT - (1 / totalDistKm), 0);
      updateUI();
    },
    then(onFulfilled, onRejected) {
      return promise.then(onFulfilled, onRejected);
    },
    destroy() {
      if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = null;
      }
      marker.remove();
    }
  };
}
