import graphClient from '../graphql/graph_client';
import { GetMapStyle } from "../graphql/map-styles-graph";

import hikingMapStyleImage from "../../img/map_style/hiking_map_style.jpg"
import historicalMapStyleImage from "../../img/map_style/historical_map_style.jpg"
import nationalMapStyleImage from "../../img/map_style/national_map_style.jpg"
import standardMapStyleImage from "../../img/map_style/standard_map_style.png"
import satelliteMapStyleImage from "../../img/map_style/satellite_map_style.jpg"
import parksMapStyleImage from "../../img/map_style/parks_map_style.jpg"

var threeDimensionalModeIsEnabled = false;

export class ThreeDimensionalMapManager {

  static enable3DMode() {
    const map = window.map;
    if (map.getSource('mapbox-dem') == null) {
      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });
    }
    if (map.getTerrain() == null) {
      map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2.0 });
    }

    // Set map pitch
    map.setPitch(50);

    // Set max pitch to reduce the horizon seen and reduce unneccessary tile loading
  }

  static toggle3DMode() {
    if (threeDimensionalModeIsEnabled) {
      threeDimensionalModeIsEnabled = false;
      ThreeDimensionalMapManager.disable3DMode();
    } else {
      threeDimensionalModeIsEnabled = true;
      ThreeDimensionalMapManager.enable3DMode();
    }
  }

  static disable3DMode() {
    const map = window.map;
    if (map.getTerrain()) {
      map.setTerrain(null);
    }
    map.setPitch(0);
  }
}

export class SavedCenter {
  lat: number;
  lng: number;
  zoom: number;

  constructor(lat: number, lng: number, zoom: number) {
    this.lat = lat;
    this.lng = lng;
    this.zoom = zoom;
  }

  static saveCurrentCenter() {
    // Serialize the current SavedCenter instance to a JSON string
    const center = window.map.getCenter()
    const zoom = window.map.getZoom()
    const centerObject = {
      lat: center.lat,
      lng: center.lng,
      zoom: zoom
    };
    localStorage.setItem('currentCenter', JSON.stringify(centerObject));
  }

  static getCurrentCenter() {
    const centerString = localStorage.getItem('currentCenter');
    if (centerString) {
      // Parse the JSON string back into an object
      const centerObject = JSON.parse(centerString);
      return new SavedCenter(centerObject.lat, centerObject.lng, centerObject.zoom);
    } else {
      // Return the default center if none was found
      return new SavedCenter(0, 0, 0); // Assuming default center is (0, 0) with zoom 0
    }
  }

  lngLat() {
    return [this.lng, this.lat]
  }

}


export class HiikerMapStyle {
  styleUrl: string;
  name: string;
  image: string; // Image URL or path
  isPro: boolean;
  isPrintMap: boolean;

  constructor(styleUrl: string, name: string, image: string, isPro: boolean, isPrintMap: boolean = true) {
    this.styleUrl = styleUrl;
    this.name = name;
    this.image = image;
    this.isPro = isPro;
    this.isPrintMap = isPrintMap
  }

  toString() {
    switch (this.name) {
      case "Standard":
        return "standard";
      case "Satellite":
        return "satellite";
      case "Parks":
        return "parks";
      case "Hiking+":
        return "hiking";
      case "National+":
        return "national";
      case "Historical+":
        return "historical";
      case "Outdoors":
        return "outdoors";
      default:
        return "standard";
    }
  }

  toPrintMapString() {
    switch (this.name) {
      case "Standard":
        return "standard-print-map";
      case "Satellite":
        return "satellite-print-map";
      case "Parks":
        return "parks-print-map";
      case "Hiking+":
        return "hiking-print-map";
      case "National+":
        return "national-print-map";
      case "Historical+":
        return "historical-print-map";
      case "Outdoors":
        return "outdoors-print-map";
      default:
        return "standard-print-map";
    }
  }

  printMapUrl() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/${this.toPrintMapString()}.json?userId=${userId}`;
    return url
  }

  saveCurrentStyle() {
    // Serialize the current HiikerMapStyle instance to a JSON string
    const styleObject = {
      styleUrl: this.styleUrl,
      name: this.name,
      image: this.image,
      isPro: this.isPro
    };
    localStorage.setItem('currentHiikerMapStyle', JSON.stringify(styleObject));
  }

  static fromString(styleString: string) {
    switch (styleString) {
      case "standard":
        return HiikerMapStyle.standard();
      case "satellite":
        return HiikerMapStyle.satellite();
      case "parks":
        return HiikerMapStyle.parks();
      case "hiking":
        return HiikerMapStyle.hiking();
      case "national":
        return HiikerMapStyle.national();
      case "historical":
        return HiikerMapStyle.historical();
      default:
        return HiikerMapStyle.standard();
    }
  }


  static getCurrentStyle(userIsPro: boolean = false) {
    const styleString = localStorage.getItem('currentHiikerMapStyle');
    if (styleString) {
      // Parse the JSON string back into an object
      const styleObject = JSON.parse(styleString);
      let style = new HiikerMapStyle(styleObject.styleUrl, styleObject.name, styleObject.image, styleObject.isPro);
      if (style.isPro) {
        if (userIsPro) {
          return style;
        } else {
          return HiikerMapStyle.standard();
        }
      } else {
        return style;
      }
    } else {
      // Return the default style if none was found
      return HiikerMapStyle.standard(); // Assuming standard() is correctly defined with all properties
    }
  }

  static getAllStyles(): HiikerMapStyle[] {
    let mapStyles =  [
      HiikerMapStyle.standard(),
      HiikerMapStyle.satellite(),
      HiikerMapStyle.parks(),
      HiikerMapStyle.hiking(),
      HiikerMapStyle.national(),
      HiikerMapStyle.historical(),
    ];
    if (window.flashing) {
      mapStyles.push(HiikerMapStyle.outdoors());
    }
    return mapStyles;
  }

  static standard() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/standard.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Standard",
      standardMapStyleImage, // Adjust path as necessary
      false,
      true
    );
  }

  static satellite() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/satellite.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Satellite",
      satelliteMapStyleImage, // Ensure this path is correctly adjusted
      false
    );
  }

  static parks() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/parks.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Parks",
      parksMapStyleImage, // Ensure this path is correctly adjusted
      false
    );
  }

  static hiking() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/hiking.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Hiking+",
      hikingMapStyleImage, // Adjust path as necessary
      true,
      false
    );
  }

  static national() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/national.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "National+",
      nationalMapStyleImage, // Adjust path as necessary
      true
    );
  }

  static historical() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/historical.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Historical+",
      historicalMapStyleImage, // Adjust path as necessary
      true
    );
  }

  static outdoors() {
    const localURL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let userId = window.user?.firebase_user_id || '';
    let url = `${localURL}/mapbox_style_jsons/outdoors.json?userId=${userId}`;
    return new HiikerMapStyle(
      url,
      "Outdoors",
      standardMapStyleImage, // Adjust path as necessary
      false
    );
  }

  // Method to compare two HiikerMapStyle instances, if needed
  equals(other: HiikerMapStyle): boolean {
    return this.styleUrl === other.styleUrl && this.name === other.name;
  }
}

export class MapStyle {
  id: number
  name: string
  mapStyleDescription: string
  zoomRange: Array<number>
  copyright: string
  boundingBox: Array<number>
  image: string
  styleFile: string
  styleFileUrl: string
  proPlus: boolean
  alpha: string = "100"

  constructor(id: number, name: string, mapStyleDescription: string, zoomRange: Array<number>, copyright: string, boundingBox: Array<number>, photos: string, styleFile: string, styleFileUrl: string, proPlus: boolean, alpha: string) {
    this.id = id
    this.name = name
    this.mapStyleDescription = mapStyleDescription
    this.zoomRange = zoomRange
    this.copyright = copyright
    this.boundingBox = boundingBox
    this.image = photos
    this.styleFile = styleFile
    this.styleFileUrl = styleFileUrl
    this.proPlus = proPlus
    this.alpha = alpha
  }

  public static fromMapStyle(mapStyle: object) {
    return new MapStyle(
      // @ts-ignore
      mapStyle['id'],
      // @ts-ignore
      mapStyle['name'],
      // @ts-ignore
      mapStyle['description'],
      // @ts-ignore
      mapStyle['zoomRange'],
      // @ts-ignore
      mapStyle['copyright'],
      // @ts-ignore
      mapStyle['boundingBox'],
      // @ts-ignore
      mapStyle['image'],
      // @ts-ignore
      mapStyle['styleFile'],
      // @ts-ignore
      mapStyle['styleFileUrl'],
      // @ts-ignore
      mapStyle['proPlus'],
      mapStyle['alpha']
    )
  }

  public static async get(id: number) {
    let mapStyleResponse = await graphClient.request(GetMapStyle, {id: `${id}`})
    let mapStyleData = mapStyleResponse.mapStyle
    return new MapStyle(
      mapStyleData.id,
      mapStyleData.name,
      mapStyleData.description,
      mapStyleData.zoomRange,
      mapStyleData.copyright,
      mapStyleData.boundingBox,
      mapStyleData.image,
      mapStyleData.styleFile,
      mapStyleData.styleFileUrl,
      mapStyleData.proPlus,
      "100"
    )
  }
}
