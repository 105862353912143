<template>
  <div>
    <div
      class="modal fade overflow-hidden"
      id="mapModal"
      tabindex="-1"
      aria-labelledby="mapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body position-relative p-0">
            <!-- Map container -->
            <div
              id="map"
              ref="mapContainer"
              style="width: 100%; height:100vh; position: relative;"
            >
              <!-- Distance/Elevation label overlay -->
              <div v-if="showLabels" class="label-style">
                <div>Distance: {{ currentDistance }}</div>
                <div>Elevation: {{ currentElevation }}</div>
              </div>
            </div>
            <!-- Control Buttons: Rewind, Play/Pause, Fast Forward -->
            <div v-if="animationController" class="control-buttons">
              <button class="btn btn-secondary rounded-circle d-flex align-items-center control-button" @click="animationController.rewind()">
                <span class="material-icons">fast_rewind</span>
              </button>

              <button class="btn btn-primary rounded-circle play-button d-flex align-items-center control-button" @click="togglePlayback">
                <span v-if="isPlaying" class="material-icons">pause</span>
                <span v-else class="material-icons">play_arrow</span>
              </button>

              <button class="btn btn-secondary rounded-circle d-flex align-items-center control-button" @click="animationController.fastForward()">
                <span class="material-icons">fast_forward</span>
              </button>
            </div>

            <button class="btn btn-secondary rounded-circle d-flex align-items-center control-button close-button" data-bs-dismiss="modal">
              <span class="material-icons">close</span>
            </button>
          </div>
        </div>
      </div>
      <LoadingDialog :show-loading-dialog="loading"/>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import $ from "jquery";
// Import the previewTrail helper (ensure the path is correct)
import { previewTrail } from "../utils/helpers/TrailPreview";

// GraphQL clients/queries
import graph_client from "../utils/graphql/graph_client";
import { GetTrailLineString } from "../utils/graphql/trail-graph";
import { GetPtv2PlannedTrailLineString } from "../utils/graphql/planned-trail-graph";
import { GetRecordedTrailLineStringById } from "../utils/graphql/recorded-trail-graph";
import { MeasurementPrefUtility } from "../utils/helpers/MeasurementPref";
import LoadingDialog from "../utils/shared_components/LoadingDialog.vue";

export default {
  name: "FlyoverPreview",
  components: {LoadingDialog},
  props: {
    title: { type: String, default: "Flyover Preview" }
  },
  data() {
    return {
      loading: true,
      mapInitialized: false,
      defaultCenter: [-74.5, 40],
      featureCollection: null,
      mapboxToken:
        "pk.eyJ1IjoiaGlpa2VyLW1hcHMiLCJhIjoiY2xydDBwMTU4MDFmdDJwbzd5NjFxMmlyciJ9.Q0bXbghB7N2Ji93oLrAJng",
      currentDistance: 0,
      currentElevation: 0,
      showLabels: true,
      animationController: null,
      isPlaying: true,
      finishedAnimation: false,
    };
  },
  computed: {
    labelStyle() {
      return {
        position: "absolute",
        top: "1em",
        left: "1em",
        zIndex: 999,
        background: "rgba(23, 24, 29, 0.7)",
        color: "#fff",
        padding: "8px 12px",
        borderRadius: "4px",
        fontSize: "16px",
        pointerEvents: "none"
      };
    }
  },
  mounted() {
    window.showMapModalForTrail = trail_id => {
      window.mixpanel.track('3D Trail Preview', {
        trail_id: trail_id,
        trail_type: 'Trail',
      });
      $("#mapModal").modal("show");
      this.loading = true;
      graph_client.request(GetTrailLineString, { id: trail_id }).then(data => {
        this.featureCollection = JSON.parse(data.trail.featureCollection);
        this.initializeMap();
      });
    };

    window.showMapModalForPlannedTrail = planned_trail_id => {
      window.mixpanel.track('3D Trail Preview', {
        trail_id: planned_trail_id,
        trail_type: 'Ptv2::PlannedTrail',
      });
      $("#mapModal").modal("show");
      this.loading = true;
      graph_client
        .request(GetPtv2PlannedTrailLineString, { id: planned_trail_id })
        .then(data => {
          this.featureCollection = JSON.parse(
            data.ptv2PlannedTrailById.lineStringFeatureCollection
          );
          this.initializeMap();
        });
    };

    window.showMapModalForRecordedTrail = recorded_trail_id => {
      window.mixpanel.track('3D Trail Preview', {
        trail_id: recorded_trail_id,
        trail_type: 'Recording',
      });
      $("#mapModal").modal("show");
      this.loading = true;
      graph_client
        .request(GetRecordedTrailLineStringById, { input: recorded_trail_id })
        .then(data => {
          this.featureCollection = JSON.parse(data.recording.lineString);
          this.initializeMap();
        });
    };

    $("#mapModal").on("shown.bs.modal", () => {
      if (window.flyover_map) {
        window.flyover_map.resize();
      }
    });

    $("#mapModal").on("hidden.bs.modal", () => {
      if (this.animationController) {
        this.animationController.stop();
        this.animationController = null;
      }
      if (window.flyover_map) {
        window.flyover_map.remove();
        window.flyover_map = null;
      }
      this.mapInitialized = false;
      this.currentDistance = 0;
      this.currentElevation = 0;
    });
  },
  beforeUnmount() {
    $("#mapModal").off("shown.bs.modal");
    delete window.showMapModalForRecordedTrail;
    delete window.showMapModalForPlannedTrail;
    delete window.showMapModalForTrail;
    if (this.animationController) {
      this.animationController.stop();
    }
    if (window.flyover_map) {
      window.flyover_map.remove();
      window.flyover_map = null;
    }
  },
  methods: {
    initializeMap() {
      if (this.mapInitialized) return;
      mapboxgl.accessToken = this.mapboxToken;
      window.flyover_map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: "https://hiiker.app/mapbox_style_jsons/satellite.json",
        zoom: 5,
        pitch: 60,
        bearing: 0,
        antialias: true,
        center: window.lonlat || [0.0, 0.0],
      });
      window.flyover_map.on("load", () => {
        window.flyover_map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14
        });
        window.flyover_map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
        this.renderFeatureCollection();
        window.flyover_map.resize();
      });
      window.flyover_map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      this.mapInitialized = true;
    },
    clearLayers() {
      if (!window.flyover_map) return;
      if (window.flyover_map.getLayer("feature-shadow")) {
        window.flyover_map.removeLayer("feature-shadow");
      }
      if (window.flyover_map.getLayer("feature")) {
        window.flyover_map.removeLayer("feature");
      }
      if (window.flyover_map.getSource("feature-data")) {
        window.flyover_map.removeSource("feature-data");
      }
      if (window.flyover_map.getLayer("progressTrail")) {
        window.flyover_map.removeLayer("progressTrail");
      }
      if (window.flyover_map.getSource("progressTrail")) {
        window.flyover_map.removeSource("progressTrail");
      }
    },
    renderFeatureCollection() {
      this.loading = false;
      if (!window.flyover_map) return;
      if (!this.featureCollection) {
        const center = window.lonLat || this.defaultCenter;
        window.flyover_map.flyTo({
          center: center,
          zoom: 12,
          pitch: 60,
          bearing: 0
        });
        return;
      }
      this.clearLayers();
      if (this.animationController) {
        this.animationController.stop();
        this.animationController = null;
      }
      let geojson, coordinates = [];
      if (typeof this.featureCollection === "string") {
        try {
          geojson = JSON.parse(this.featureCollection);
        } catch (e) {
          console.error("Error parsing feature collection:", e);
          return;
        }
      } else {
        geojson = this.featureCollection;
      }
      if (geojson.type === "FeatureCollection") {
        for (const feature of geojson.features) {
          if (feature.geometry && feature.geometry.coordinates) {
            if (feature.geometry.type === "LineString") {
              coordinates = feature.geometry.coordinates;
              break;
            } else if (feature.geometry.type === "Point") {
              coordinates = [feature.geometry.coordinates];
              break;
            } else if (feature.geometry.type === "Polygon") {
              coordinates = feature.geometry.coordinates[0];
              break;
            }
          }
        }
      } else if (geojson.type === "Feature") {
        if (geojson.geometry && geojson.geometry.coordinates) {
          if (geojson.geometry.type === "LineString") {
            coordinates = geojson.geometry.coordinates;
          } else if (geojson.geometry.type === "Point") {
            coordinates = [geojson.geometry.coordinates];
          } else if (geojson.geometry.type === "Polygon") {
            coordinates = geojson.geometry.coordinates[0];
          }
        }
      }
      window.flyover_map.addSource("feature-data", { type: "geojson", data: geojson });
      const features = geojson.features || [geojson];
      const feature = features[0];
      const geometryType = feature.geometry ? feature.geometry.type : null;
      window.flyover_map.addLayer({
        id: "feature-shadow",
        type: geometryType === "Point" ? "circle" : "line",
        source: "feature-data",
        paint:
          geometryType === "Point"
            ? { "circle-radius": 12, "circle-color": "#000", "circle-opacity": 0.4, "circle-blur": 3 }
            : { "line-color": "#DB2B35", "line-width": 4 }
      });
      window.flyover_map.addLayer({
        id: "feature",
        type: geometryType === "Point" ? "circle" : "line",
        source: "feature-data",
        paint:
          geometryType === "Point"
            ? { "circle-radius": 8, "circle-color": "#FF4500", "circle-opacity": 1 }
            : { "line-color": "#DB2B35", "line-width": 8, "line-opacity": 1 }
      });
      if (geometryType === "LineString" && typeof previewTrail === "function" && coordinates.length > 0) {
        this.animationController = previewTrail(
          window.flyover_map,
          coordinates,
          "medium",
          {},
          (distKm, elevM) => {
            const convertedDistance = MeasurementPrefUtility.preference().bigUnitFormatter(distKm);
            const convertedElv = MeasurementPrefUtility.preference().smallUnitFormatter(elevM);
            this.currentDistance = convertedDistance;
            this.currentElevation = convertedElv;
          }
        );
        // Set isPlaying to true initially
        this.isPlaying = true;
        // When the animation finishes, update isPlaying to false so the play button shows the play icon.
        this.animationController.then(() => {
          this.isPlaying = false;
          this.finishedAnimation = true;
        });
      } else {
        this.fitToCoordinates(coordinates);
      }
    },
    fitToCoordinates(coordinates) {
      if (!coordinates || coordinates.length === 0 || !window.flyover_map) return;
      const bounds = new mapboxgl.LngLatBounds();
      coordinates.forEach(coord => bounds.extend(coord));
      window.flyover_map.fitBounds(bounds, { padding: 50, maxZoom: 15, pitch: 60 });
    },
    togglePlayback() {
      // If there's no animation controller, do nothing
      if (!this.animationController) return;

      // If the animation is finished, reinitialize it
      if (this.finishedAnimation) {
        this.renderFeatureCollection(); // This will recreate the controller
        this.finishedAnimation = false;
        this.isPlaying = true;
        return;
      }

      // Otherwise, toggle play/pause
      if (this.isPlaying) {
        this.animationController.pause();
        this.isPlaying = false;
      } else {
        this.animationController.resume();
        this.isPlaying = true;
      }
    },
  },
};
</script>

<style scoped>
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100vh;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100vh;
  border: 0;
  border-radius: 0;
}
.close-button {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1000;
  width: 40px;
  height: 40px !important;
}
.play-button {
  position: relative;
  z-index: 1000;
}
.control-buttons {
  position: absolute;
  top: 1em;
  right: 5.5em;
  display: flex;
  gap: 10px;
  z-index: 1000;
}
.control-button {
  width: 40px;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-border {
  width: 4rem;
  height: 4rem;
}

.label-style{
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 999;
  background: rgba(23, 24, 29, 0.7);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;

  @media (max-width: 768px) {
    top: unset;
    bottom: 3em;
    left: 10px;
  }
}
</style>
