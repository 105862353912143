<template>
  <div class="col-12 col-lg-9">
    <!-- Success Message -->
    <div v-if="upgradeSucceeded" class="container py-3 rounded-lg">
      <div class="alert alert-primary">
        <h4>Success</h4>
        <p>You have upgraded to a yearly subscription.</p>
      </div>
    </div>

    <h1 class="mb-3 mb-2 display-4">
      <b>Your Subscription</b>
    </h1>

    <p class="text-muted mb-3">
      <b>Data correct as of:</b>
      {{ formatDate(revenueCatData.requestDate) }}
    </p>

    <div class="container bg-dark py-3 rounded-lg">
      <!-- No Subscription State -->
      <div v-if="!hasActiveEntitlements" class="row">
        <div class="col-3">
          <img :src="freeImage" class="img-fluid rounded">
        </div>
        <div class="col-9 d-flex align-items-center">
          <span>
            <h4 class="display-5 mb-3">
              <b>You don't have an active subscription!</b>
            </h4>
            <p>
              <a :href="purchasesPath" class="text-yellow p-0 d-flex align-items-center">
                Check out our latest offers!
                <span class="material-icons ml-2">arrow_right_alt</span>
              </a>
            </p>
          </span>
        </div>
      </div>

      <!-- Active Subscriptions -->
      <div v-else>
        <div v-for="(entitlement, key) in activeEntitlements" :key="key" class="row mb-4">
          <div class="col-3">
            <img :src="subscriptionImage(entitlement.identifier)" class="img-fluid rounded">
          </div>
          <div class="col-9 d-flex align-items-center">
            <span>
              <h4 class="display-5 mb-3">
                <b>Hiiker {{ entitlement.identifier }}</b>
                <span class="badge bg-info ms-2">{{ subscriptionTypeBadge(entitlement) }}</span>
              </h4>

              <div class="subscription-details">
                <p v-if="entitlement.originalPurchaseDate" class="text-muted">
                  <b>Start Date:</b>
                  {{ formatDate(entitlement.originalPurchaseDate) }}
                </p>

                <p v-if="entitlement.expirationDate" class="text-muted">
                  <b>Expiry Date:</b>
                  {{ formatDate(entitlement.expirationDate) }}
                </p>
                <p v-else class="text-success">
                  <b>Lifetime Access</b>
                </p>

                <p class="text-muted" v-if="entitlement.productIdentifier">
                  <b>Product ID:</b>
                  <code>{{ entitlement.productIdentifier }}</code>
                </p>

                <div class="subscription-links mt-3">
                  <a v-if="showManagementLink(entitlement.store)"
                     :href="managementLink(entitlement.store)"
                     target="_blank"
                     class="text-yellow">
                    <u>Manage on {{ storeName(entitlement.store) }}</u>
                  </a>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <p class="text-muted mt-3">
      <b>UserID:</b>
      {{ firebaseUserId}}
    </p>
  </div>
</template>

<script>
import { Purchases } from "@revenuecat/purchases-js";
import FreeImage from "../img/FREE-thumbnail.png"
import ProImage from "../img/PROPLUS-thumbnail.webp"

export default {
  data() {
    return {
      revenueCatData: {},
      upgradeSucceeded: false,
      freeImage: '',
      firebaseUserId: window.user.firebase_user_id
    };
  },
  computed: {
    hasActiveEntitlements() {
      return Object.keys(this.revenueCatData.entitlements?.active || {}).length > 0;
    },

    activeEntitlements() {
      return this.revenueCatData.entitlements?.active || {};
    },

    hasAdditionalProducts() {
      return Object.keys(this.revenueCatData.allPurchaseDatesByProduct || {}).length > 0;
    },

    purchasesPath() {
      return window.location.origin + '/purchases';
    }
  },
  methods: {
    subscriptionImage(identifier) {
      return identifier.includes('PRO')
        ? ProImage
        : FreeImage;
    },

    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },

    subscriptionTypeBadge(entitlement) {
      if (!entitlement.expirationDate) return 'Lifetime';
      return entitlement.willRenew ? 'Auto-Renew' : 'Non-Renewing';
    },

    storeName(store) {
      const storeNames = {
        stripe: 'Stripe',
        appstore: 'Apple AppStore',
        playstore: 'Google PlayStore'
      };
      return storeNames[store] || store;
    },

    showManagementLink(store) {
      return ['stripe', 'appstore', 'playstore'].includes(store);
    },

    managementLink(store) {
      // Use window.stripeManagementUrl for Stripe
      if (store === 'stripe') {
        return window.stripeManagementUrl;
      }

      // For Apple AppStore or Google PlayStore, use the URLs from revenueCatData
      const paths = {
        appstore: this.revenueCatData.managementURLs?.appstore,
        playstore: this.revenueCatData.managementURLs?.playstore
      };
      return paths[store];
    }
  },
  async mounted() {
    try {
      const purchases = Purchases.configure(window.rcPublicKey, window.user.firebase_user_id);
      this.revenueCatData = await purchases.getCustomerInfo();
      this.freeImage = FreeImage
    } catch (error) {
      console.error('Error fetching RevenueCat data:', error);
    }
  }
};
</script>

<style scoped>
.subscription-links a {
  margin: 8px 0;
  display: block;
}

.text-yellow {
  color: #ffd700;
}

.material-icons {
  vertical-align: middle;
}

.product-list {
  list-style-type: none;
  padding-left: 0;
}

.badge {
  font-size: 0.75em;
  vertical-align: middle;
}

.subscription-details p {
  margin-bottom: 0.5rem;
}

code {
  font-family: monospace;
  background-color: #2d2d2d;
  padding: 2px 4px;
  border-radius: 3px;
}
</style>
