import { gql } from 'graphql-request'

export const GetMapStyles = gql`
  query GetMapStyles {
    mapStyles {
      name
      id
      description
      zoomRange
      copyright
      styleFile
      styleFileUrl
      image
      proPlus
    }
  }
`;


export const GetMapStylesTags = gql`
  query GetMapStylesTags {
    tags(taggableType:"MapStyle"){
        id
        name
        taggingsCount
        trails {
            slug
        }
    }
  }
`;

export const GetMapStyle = gql`
  query GetMapStyleById($id:ID!) {
    mapStyle(id:$id){
      name
      id
      description
      zoomRange
      styleFile
      image
      styleFileUrl
      proPlus
    }
  }`

export const GetMapStyleByTag = gql`
  query GetMapStyleByTag($tags: [String!]!) {
    mapStylesByTags(tags: $tags){
      name
      id
      description
      zoomRange
      copyright
      styleFile
      image
      styleFileUrl
      proPlus
    }
  }`

export const GetMapStylesNearby = gql`
  query GetMapStylesNearby($lon:Float, $lat:Float) {
    mapStylesNearby(lon:$lon, lat:$lat) {
      name
      id
      description
      zoomRange
      copyright
      styleFile
      image
      proPlus
    }
  }
`

export const GetMapStylesCountries = gql`
query GetMapStyleCountries {
  mapStyleCountries {
    id
    name
    flagImage {
        lgUrl
    }
    mapStyles {
        id
    }
  }
}
`

export const GetMapStylesSearch = gql`
query GetMapStylesSearch($string: String!) {
  mapStylesSearch(searchString: $string) {
    name
    id
    description
    zoomRange
    copyright
    styleFile
    image
    proPlus
  }
}
`

export const GetMapStylesByCountry = gql`
query GetMapStylesByCountry($id: ID!) {
  mapStylesByCountryId(countryId: $id) {
    name
    id
    description
    zoomRange
    copyright
    styleFile
    image
    proPlus
  }
}
`
