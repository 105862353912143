// Import necessary styles and libraries
import Vue from 'vue';
import Purchases from "./Purchases.vue";

// Check if the specific element exists in the DOM
if (document.querySelector('#purchases')) {
  // Create a new Vue instance and mount it to the element
  window.vue = new Vue({
    render: (createElement) => {
      return createElement(Purchases)
    },
  }).$mount('#purchases');
}
