import axios from "axios";
import { plainToClass } from "class-transformer";
import { point, midpoint, distance } from "@turf/turf";
import mapboxgl from "mapbox-gl";

export class TypesenseResponse {
  facet_counts: any[];
  found: number;
  hits: Hit[];
  out_of: number;
  page: number;
  request_params: RequestParams;
  search_cutoff: boolean;
  search_time_ms: number;

  constructor(
    facet_counts: any[],
    found: number,
    hits: Hit[],
    out_of: number,
    page: number,
    request_params: RequestParams,
    search_cutoff: boolean,
    search_time_ms: number
  ) {
    this.facet_counts = facet_counts;
    this.found = found;
    this.hits = hits;
    this.out_of = out_of;
    this.page = page;
    this.request_params = request_params;
    this.search_cutoff = search_cutoff;
    this.search_time_ms = search_time_ms;
  }

  // Add bounds as a parameter to the get function
  static async get(
    query: string = "*",
    filterString: string | null = null,
    bounds: mapboxgl.LngLatBounds | null = null,
    typesensePageCount: number | null = null,
    perPage: number = 100
  ): Promise<TypesenseResponse | null> {

    // Remove the getDistance function since it's no longer needed

    const axiosPostClient = axios.create({
      headers: {
        'x-typesense-api-key': window.typesenseSearchApiKey,
        'Content-Type': 'application/json'
      },
    })
    // Update this line to pass the bounds to createTypesensePostObject
    let postObject = this.createTypesensePostObject(bounds, query, filterString, typesensePageCount, perPage)
    // ... rest of the function
    let postResponse = await axiosPostClient.post(postObject.url, postObject.body)
    let hits = postResponse.data.results.map((result: any) => result.hits).flat()
    let jsonObject = { hits: hits } as Object;
    return plainToClass(TypesenseResponse, jsonObject)
  }

  static createTypesensePostObject(
    bounds: mapboxgl.LngLatBounds | null,
    query: string,
    filterString: string | null = null,
    typesensePageCount: number | null = null,
    perPage: number = 100
  ): { url: string, body: any } {
    let filterBy = "";

    if (bounds) {
      // const ne = bounds.getNorthEast();
      // const sw = bounds.getSouthWest();
      //
      // // Create a filter string based on the bounds
      // filterBy += `latitude:>${sw.lat} AND latitude:<${ne.lat} AND longitude:>${sw.lng} AND longitude:<${ne.lng}`;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const nw = new mapboxgl.LngLat(sw.lng, ne.lat);
      const se = new mapboxgl.LngLat(ne.lng, sw.lat);

      // Create a filter string based on the bounds using the location field
      filterBy += `location:(${nw.lat},${nw.lng},${ne.lat},${ne.lng},${se.lat},${se.lng},${sw.lat},${sw.lng})`;
    }



    if (filterString) {
      filterBy += filterString
    }
    return {
      url: 'https://' + window.typesenseUrl + "/multi_search",
      body: {
        'searches': [...Array(typesensePageCount).keys()].map(e => e + 1).map((i) => {
          return {
            "collection": "trails",
            "q": query,
            "query_by": "name,short_address,keywords,tags",
            "filter_by": filterBy,
            "per_page": `${perPage}`,
            "page": i,
          }
        })
      }
    }
  }

  // static async get(
  //   query: string = "*",
  //   filterString: string | null = null,
  //   center: number[] | null = null,
  //   typesensePageCount: number | null = null
  // ): Promise<TypesenseResponse | null> {
  //
  //   function getDistance() {
  //     const bounds = window.map.getBounds();
  //     const topLeft = point([bounds.getNorthEast().lng, bounds.getNorthEast().lat]);
  //     const topRight = point([bounds.getSouthWest().lng, bounds.getNorthEast().lat]);
  //     const bottomLeft = point([bounds.getNorthEast().lng, bounds.getSouthWest().lat]);
  //     const bottomRight = point([bounds.getSouthWest().lng, bounds.getSouthWest().lat]);
  //
  //     const middleLeft = midpoint(topLeft, bottomLeft);
  //     const middleRight = midpoint(topRight, bottomRight);
  //     return distance(middleLeft, middleRight, { units: 'meters' }) * 0.4
  //   }
  //
  //   const axiosPostClient = axios.create({
  //     headers: {
  //       'x-typesense-api-key': window.typesenseSearchApiKey,
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   debugger
  //   let postObject = this.createTypesensePostObject(center!, getDistance(), query, filterString, typesensePageCount)
  //   debugger
  //   let postResponse = await axiosPostClient.post(postObject.url, postObject.body)
  //   let hits = postResponse.data.results.map((result: any) => result.hits).flat()
  //   let jsonObject = { hits: hits } as Object;
  //   return plainToClass(TypesenseResponse, jsonObject)
  // }
  //
  // static createTypesensePostObject(
  //   center: number[] | null,
  //   distanceMeters: number | null,
  //   query: string,
  //   filterString: string | null = null,
  //   typesensePageCount: number | null = null): { url: string, body: any } {
  //   let filterBy = "";
  //   if (distanceMeters && center) {
  //     let distanceKilometers = Math.round(distanceMeters / 1000)
  //     if (distanceKilometers < 1.0) {
  //       distanceKilometers = 1.0
  //     }
  //     let locationString = `location:(${center[1].toFixed(3)},${center[0].toFixed(3)},${distanceKilometers}km)`
  //     filterBy += locationString
  //   }
  //
  //   if (filterString) {
  //     filterBy += filterString
  //   }
  //   return {
  //     url: 'https://' + window.typesenseUrl + "/multi_search",
  //     body: {
  //       'searches': [...Array(typesensePageCount).keys()].map(e => e + 1).map((i) => {
  //         return {
  //           "collection": "trails",
  //           "q": query,
  //           "query_by": "name,short_address,keywords,tags",
  //           "filter_by": filterBy,
  //           "per_page": 250,
  //           "page": i,
  //         }
  //       })
  //     }
  //   }
  // }

  toGeoJson(): any {
    let geoJson = {}
    geoJson["type"] = "FeatureCollection"
    let features = this.hits.map((trailHit) => {
      return {
        "type": "Feature",
        "properties": trailHit.document,
        "geometry": {
          "type": "Point",
          "coordinates":
            [
              trailHit.document.location[1],
              trailHit.document.location[0]
            ]
        }
      }
    })
    geoJson["features"] = features
    return geoJson
  }
}

export interface Hit {
  document: Document;
  highlights: any[];
  text_match: number;
}

export interface Document {
  average_rating: number;
  country_name: string;
  country_slug: string;
  difficulty: Difficulty;
  distance: number;
  duration_minutes: number;
  duration_string: string;
  elevation_gain: number;
  id: string;
  impressions_count: number;
  jumbo_image: string;
  keywords: string;
  location: number[];
  name: string;
  number_of_ratings: number;
  region_name: string;
  region_slug: string;
  short_address: string;
  tags: string[];
  type: TrailType;
  static_map_url: string;
}

export enum TrailType {
  The100To300KMTrail = "100To300KmTrail",
  The300KMPlusTrail = "300KmPlusTrail",
  The30To100KMTrail = "30To100KmTrail",
  The5KMTrail = "<5kmTrail",
  The5To30KMTrail = "5To30KmTrail",
}

export enum Difficulty {
  Easy = "Easy",
  Medium = "Medium",
  Difficult = "Difficult",
  ExtraDifficult = "Extra Difficult"
}

export namespace Difficulty {
  export function fromString(difficulty: string): Difficulty {
    switch (difficulty) {
      case "Easy":
        return Difficulty.Easy
      case "Medium":
        return Difficulty.Medium
      case "Difficult":
        return Difficulty.Difficult
      case "Extra Difficult":
        return Difficulty.ExtraDifficult
      default:
        return Difficulty.Easy
    }
  }
}

export interface RequestParams {
  collection_name: string;
  per_page: number;
  q: string;
}


export class Convert {
  public static toTypesenseResponse(json: string): TypesenseResponse {
    return cast(JSON.parse(json), r("TypesenseResponse"));
  }

  public static typesenseResponseToJson(value: TypesenseResponse): string {
    return JSON.stringify(uncast(value, r("TypesenseResponse")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`,);
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {
      }
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach(key => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems") ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty("props") ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  "TypesenseResponse": o([
    { json: "facet_counts", js: "facet_counts", typ: a("any") },
    { json: "found", js: "found", typ: 0 },
    { json: "hits", js: "hits", typ: a(r("Hit")) },
    { json: "out_of", js: "out_of", typ: 0 },
    { json: "page", js: "page", typ: 0 },
    { json: "request_params", js: "request_params", typ: r("RequestParams") },
    { json: "search_cutoff", js: "search_cutoff", typ: true },
    { json: "search_time_ms", js: "search_time_ms", typ: 0 },
  ], false),
  "Hit": o([
    { json: "document", js: "document", typ: r("Document") },
    { json: "highlights", js: "highlights", typ: a("any") },
    { json: "text_match", js: "text_match", typ: 0 },
  ], false),
  "Document": o([
    { json: "average_rating", js: "average_rating", typ: 0 },
    { json: "country_name", js: "country_name", typ: r("CountryName") },
    { json: "country_slug", js: "country_slug", typ: r("CountrySlug") },
    { json: "difficulty", js: "difficulty", typ: r("Difficulty") },
    { json: "distance", js: "distance", typ: 0 },
    { json: "duration_minutes", js: "duration_minutes", typ: 0 },
    { json: "duration_string", js: "duration_string", typ: "" },
    { json: "elevation_gain", js: "elevation_gain", typ: 3.14 },
    { json: "id", js: "id", typ: "" },
    { json: "impressions_count", js: "impressions_count", typ: 0 },
    { json: "jumbo_image", js: "jumbo_image", typ: "" },
    { json: "keywords", js: "keywords", typ: "" },
    { json: "location", js: "location", typ: a(3.14) },
    { json: "name", js: "name", typ: "" },
    { json: "number_of_ratings", js: "number_of_ratings", typ: 0 },
    { json: "region_name", js: "region_name", typ: "" },
    { json: "region_slug", js: "region_slug", typ: "" },
    { json: "short_address", js: "short_address", typ: "" },
    { json: "tags", js: "tags", typ: a("") },
    { json: "type", js: "type", typ: r("Type") },
    { json: "static_map_url",js: "static_map_url", typ: "" }
  ], false),
  "RequestParams": o([
    { json: "collection_name", js: "collection_name", typ: "" },
    { json: "per_page", js: "per_page", typ: 0 },
    { json: "q", js: "q", typ: "" },
  ], false),
  "CountryName": [
    "*"
  ],
  "CountrySlug": [
    "*"
  ],
  "Difficulty": [
    "Difficult",
    "Easy",
    "Extra Difficult",
    "Medium",
  ],
  "Type": [
    "100To300KmTrail",
    "300KmPlusTrail",
    "30To100KmTrail",
    "<5kmTrail",
    "5To30KmTrail",
  ],
};

