import {HiikerMapStyle, MapStyle} from "../../../utils/models/MapStyles";
import Vue from "vue";
import { MapOverlayOption, MapOverlayOptions } from "../../../utils/models/MapOverlayOptions";
import { Module, ActionTree } from "vuex";
// @ts-ignore
import baseMapStyle from "../../../utils/assets/base_style.json";
import { OverzoomUtility } from "../../../utils/helpers/OverzoomPreference";
import { RevenueCatUtil } from "../../../utils/models/RevenueCatUser";
import { OnBoardingGoal } from "../../../utils/models/OnBoardingGoal";
import getQueryParams from "../../../utils/helpers/GetQueryParamsHelper";
import {RevenueCatWrapper} from "../../../utils/RevenueCatWrapper";

export class MapStylesState {
  currentMapStyle: HiikerMapStyle | null = null;
  printMapMode: boolean = false;
}

const state = Vue.observable(new MapStylesState());

const actions: ActionTree<MapStylesState, any> = {
  async initialiseStore(store) {
    await store.dispatch('setMapStyleFromQueryParams');
    const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus()
    store.state.currentMapStyle = HiikerMapStyle.getCurrentStyle(isProOrProPlus);
  },
  async setMapStyleFromQueryParams({ commit, dispatch }) {
    const params = new URLSearchParams(window.location.search);
    if (params.has('mapStyle')) {
      // You can then get the value of the parameter like so:
      const value = params.get('mapStyle');
      let mapStyle = HiikerMapStyle.fromString(value)
      if (!mapStyle.isPro) {
        mapStyle.saveCurrentStyle()
        setTimeout(() => {
          if (window.map) {
            window.map.setStyle(mapStyle.styleUrl)
          }
        }, 1500)
        return
      }
      const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus()
      if (window.user == null) {
        setTimeout(() => {
          window.onBoardingVue.launchFor(OnBoardingGoal.MapStyles)
        }, 1500)
        return
      }
      if (mapStyle.isPro &&  !isProOrProPlus) {
        setTimeout(() => {
          window.location.href = '/pricing'
        }, 1500)
      } else {
        mapStyle.saveCurrentStyle()
        setTimeout(() => {
          if (window.map) {
            window.map.setStyle(mapStyle.styleUrl)
          }
        }, 1500)
      }
    }
  },
}

const mutations = {
  setCurrentMapStyle(state, mapStyle: HiikerMapStyle) {
    state.currentMapStyle = mapStyle
  },
  setPrintMapMode(state, printMapMode: boolean) {
    state.printMapMode = printMapMode
  }
}

const getters = {

}

const modules = {}

export default class MapStylesModule implements Module<any, MapStylesState> {
  namespaced = true
  state = state
  // @ts-ignore
  actions = actions
  mutations = mutations
  getters = getters
  modules = modules
}
