
export enum MeasurementPref {
  KilometersMeters = 'kilometersMeters',
  MilesFeet = 'milesFeet',
  MilesYards = 'milesYards',
  KilometersFeet  = 'kilometersFeet',
  MilesMeters = 'milesMeters',
  MilesFurlongs = 'milesFurlongs',
  MilesFathoms  = 'milesFathoms',
  NauticalMilesYards  = 'nauticalMilesYards',
  MilesInches = 'milesInches',
  LightYearsMeters = 'lightYearsMeters'
}

export class MeasurementPrefUtility {
  measurementPref: MeasurementPref;

  constructor(measurementPref: MeasurementPref) {
    this.measurementPref = measurementPref;
  }

  isMetric(): boolean {
    switch (this.measurementPref) {
      case MeasurementPref.KilometersMeters: return true;
      case MeasurementPref.MilesFeet: return false;
      case MeasurementPref.MilesYards: return false;
      case MeasurementPref.KilometersFeet: return true;
      case MeasurementPref.MilesMeters: return false;
      case MeasurementPref.MilesFurlongs: return false;
      case MeasurementPref.MilesFathoms: return false;
      case MeasurementPref.NauticalMilesYards: return false;
      case MeasurementPref.MilesInches: return false;
      case MeasurementPref.LightYearsMeters: return true;
    }
  }

  toPrettyString(): string {
    switch (this.measurementPref) {
      case MeasurementPref.KilometersMeters: return 'Kilometers/Meters';
      case MeasurementPref.MilesFeet: return 'Miles/Feet';
      case MeasurementPref.MilesYards: return 'Miles/Yards';
      case MeasurementPref.KilometersFeet: return 'Kilometers/Feet';
      case MeasurementPref.MilesMeters: return 'Miles/Meters';
      case MeasurementPref.MilesFurlongs: return 'Miles/Furlongs';
      case MeasurementPref.MilesFathoms: return 'Miles/Fathoms';
      case MeasurementPref.NauticalMilesYards: return 'Nautical Miles/Yards';
      case MeasurementPref.MilesInches: return 'Miles/Inches';
      case MeasurementPref.LightYearsMeters: return 'Light Years/Meters';
    }
  }

  static preference(): MeasurementPrefUtility {
    if (window.user && window.user.measurement_preference) {
      // @ts-ignore
      return MeasurementPrefUtility.preferenceFromString(window.user.measurement_preference.toLowerCase())
    } else if (window.units) {
      return MeasurementPrefUtility.preferenceFromString(window.units)
    } else {
      return new MeasurementPrefUtility(MeasurementPref.KilometersMeters)
    }
  }

  static preferenceFromString(string: string): MeasurementPrefUtility {
    switch (string) {
      case 'kilometersMeters'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.KilometersMeters);
      case 'milesFeet'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesFeet);
      case 'milesYards'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesYards);
      case 'kilometersFeet'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.KilometersFeet);
      case 'milesMeters'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesMeters);
      case 'milesFurlongs'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesFurlongs);
      case 'milesFathoms'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesFathoms);
      case 'nauticalMilesYards'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.NauticalMilesYards);
      case 'milesInches'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.MilesInches);
      case 'lightYearsMeters'.toLowerCase(): return new MeasurementPrefUtility(MeasurementPref.LightYearsMeters);
      default: return new MeasurementPrefUtility(MeasurementPref.KilometersMeters);
    }
  }

  mapbox(): string {
    switch (this.measurementPref) {
      case MeasurementPref.KilometersMeters: return 'metric';
      case MeasurementPref.MilesFeet: return 'imperial';
      case MeasurementPref.MilesYards: return 'imperial';
      case MeasurementPref.KilometersFeet: return 'metric';
      case MeasurementPref.MilesMeters: return 'imperial';
      case MeasurementPref.MilesFurlongs: return 'imperial';
      case MeasurementPref.MilesFathoms: return 'imperial';
      case MeasurementPref.NauticalMilesYards: return 'nautical';
      case MeasurementPref.MilesInches: return 'imperial';
      case MeasurementPref.LightYearsMeters: return 'metric';
    }
  }

  meter(): MeterUnit {
    switch (this.measurementPref) {
      case MeasurementPref.KilometersMeters: return MeterUnit.Meter;
      case MeasurementPref.MilesFeet: return MeterUnit.Feet;
      case MeasurementPref.MilesYards: return MeterUnit.Yards;
      case MeasurementPref.KilometersFeet: return MeterUnit.Feet;
      case MeasurementPref.MilesMeters: return MeterUnit.Meter;
      case MeasurementPref.MilesFurlongs: return MeterUnit.Furlongs;
      case MeasurementPref.MilesFathoms: return MeterUnit.Fathoms;
      case MeasurementPref.NauticalMilesYards: return MeterUnit.Yards;
      case MeasurementPref.MilesInches: return MeterUnit.Inches;
      case MeasurementPref.LightYearsMeters: return MeterUnit.Meter;
    }
  }

  kilometer(): KilometerUnit {
    switch (this.measurementPref) {
      case MeasurementPref.KilometersMeters: return KilometerUnit.Kilometers;
      case MeasurementPref.MilesFeet: return KilometerUnit.Miles;
      case MeasurementPref.MilesYards: return KilometerUnit.Miles;
      case MeasurementPref.KilometersFeet: return KilometerUnit.Kilometers;
      case MeasurementPref.MilesMeters: return KilometerUnit.Miles;
      case MeasurementPref.MilesFurlongs: return KilometerUnit.Miles;
      case MeasurementPref.MilesFathoms: return KilometerUnit.Miles;
      case MeasurementPref.NauticalMilesYards: return KilometerUnit.NauticalMiles;
      case MeasurementPref.MilesInches: return KilometerUnit.Miles;
      case MeasurementPref.LightYearsMeters: return KilometerUnit.LightYears;
    }
  }

  smallUnitValue(value: number, convertToBigUnitAfter1000M: Boolean = false): number {
    if (convertToBigUnitAfter1000M && value > 1000.0) {
      let kmValue = value / 1000
      return KilometerUnitUtility.convertKilometerTo(kmValue, this.kilometer())
    }
    return MeterUnitUtility.convertMeterTo(value, this.meter())
  }

  bigUnitValue(value: number, convertToSmallUnitBefore1000M: Boolean = false): number {
    if (convertToSmallUnitBefore1000M && value < 1000.0) {
      let meterValue = value * 1000
      return MeterUnitUtility.convertMeterTo(meterValue, this.meter())
    }
    return KilometerUnitUtility.convertKilometerTo(value, this.kilometer())
  }

  bigUnitFormatter(value: number, convertToSmallUnitBefore1000M: Boolean = false): string {
    if (convertToSmallUnitBefore1000M && value < 1000.0) {
      let meterValue = value * 1000
      return MeterUnitUtility.convertMeterTo(meterValue, this.meter()) + this.meterSymbol()
    }
    return KilometerUnitUtility.convertKilometerTo(value, this.kilometer()) + ' '  + this.kilometerSymbol()
  }

  smallUnitFormatter(value: number, convertToBigUnitAfter1000M: Boolean = false): string {
    if (convertToBigUnitAfter1000M && value > 1000.0) {
      let kmValue = value / 1000
      return KilometerUnitUtility.convertKilometerTo(kmValue, this.kilometer()) + ' ' + this.kilometerSymbol()
    }
    return MeterUnitUtility.convertMeterTo(value, this.meter()) + this.meterSymbol()
  }

  meterSymbol(): string {
    switch (this.meter()) {
      case MeterUnit.Meter: return 'm';
      case MeterUnit.Feet: return 'ft';
      case MeterUnit.Yards: return 'yd';
      case MeterUnit.Furlongs: return 'fur';
      case MeterUnit.Fathoms: return 'ftm';
      case MeterUnit.Inches: return 'in';
    }
  }

  kilometerSymbol(): string {
    switch (this.kilometer()) {
      case KilometerUnit.Kilometers: return 'km';
      case KilometerUnit.Miles: return 'mi';
      case KilometerUnit.NauticalMiles: return 'nmi';
      case KilometerUnit.LightYears: return 'ly';
    }
  }
}


export enum MeterUnit {
  Meter,
  Feet,
  Yards,
  Furlongs,
  Fathoms,
  Inches
}

class MeterUnitUtility {
  static convertMeterTo(value: number, unit: MeterUnit): number {
    return Number((value * MeterUnitUtility.meterToUnitMultiplier(unit)).toFixed(0))
  }

  static meterToUnitMultiplier(unit: MeterUnit): number {
    switch (unit) {
      case MeterUnit.Meter: return 1.0;
      case MeterUnit.Feet: return 3.28084;
      case MeterUnit.Yards: return 1.09361;
      case MeterUnit.Furlongs: return 0.0049709695;
      case MeterUnit.Fathoms: return 0.546806;
      case MeterUnit.Inches: return 39.3701;
    }
  }
}

export enum KilometerUnit {
  Kilometers,
  Miles,
  LightYears ,
  NauticalMiles
}

class KilometerUnitUtility {

  static convertKilometerTo(value: number, unit: KilometerUnit): number {
    return Number((value * KilometerUnitUtility.kilometerToUnitMultiplier(unit)).toFixed(2))
  }
  static kilometerToUnitMultiplier(unit: KilometerUnit): number {
    switch (unit) {
      case KilometerUnit.Kilometers: return 1.0;
      case KilometerUnit.Miles: return 0.621371;
      case KilometerUnit.LightYears: return 0.00000000000010570008340247;
      case KilometerUnit.NauticalMiles: return 0.539957;
    }
  }
}

