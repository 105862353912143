import Vue from "vue";
import FlyoverPreview from "./FlyoverPreview.vue";

if (document.querySelector('#flyover_preview')) {
  window.flyover_vue = new Vue({
    render: (createElement) => {
      return createElement(FlyoverPreview);
    }
  }).$mount('#flyover_preview');
}
